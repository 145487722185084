<template>
    <ul class="market-selector">
        <li
            v-for="(locale, index) in data"
            :key="index"
            class="market-selector__market"
        >
            <a
                :href="'/' + locale.site + '/'"
                class="market-link"
                :class="{active: locale === active}"
            >
                <img
                    loading="lazy"
                    :src="'/icons/' + locale.icon"
                    :alt="locale.title"
                    class="market-link__image"
                />

                <span class="market-link__text">{{locale.title}}</span>
            </a>
        </li>
    </ul>
</template>
<script>
  export default {
      props: {
          data: {
              type: Array,
              required: true,
              default: () => []
          }
      },
      data() {
          return {
              expand: false,
              active: {}
          }
      },
      mounted() {
          let current = this.$store.state.currentSiteLocale;
          for(var i = 0; i < this.data.length; i++) {
              if(current == this.data[i].site) {
                  this.active = this.data[i];
              }
          }
      }
  }
</script>
<style lang="scss" scoped>
    .market-selector {
        width: 100%;
        @include grid(2,20px);

        @include device(pad) {
            @include grid(4,20px);
        }

        @include device(desktop) {
            @include grid(6,20px);
        }

        &__market {
            .market-link {
                @include flex(start);

                &__image { @include size(30px); }

                &__text {
                    color: $white;
                    margin-left: 10px;
                    font-size: 14px;
                }

                &:hover {
                    .market-link__text { color: $orange; }
                }
            }

            .market-link.active {
                .market-link__text {
                    color: $orange;
                    font-weight: bold;
                }
            }
        }
    }
</style>
