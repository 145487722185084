<template>
    <a
        v-if="this.redirect"
        :href="this.redirect"
        target="_blank"
        @click="$emit('click')"
        class="btn bxs"
        :class="[
            'btn-' + bg,
            'btn-' + size
        ]"
    >
            <span>{{ $store.state.translations.to_application || 'Till ansökan' }}</span>
        <i
            v-if="icon"
            :class="icon"
            class="p h margin-left-2"
        ></i>
    </a>
</template>
<script>
export default {
    props: {
        link: {
            type: Object,
            required: false
        },
        bg: {
            type: String,
            default: 'transparent',
            required: false
        },
        size: {
            type: String,
            default: 'md',
            required: false
        },
        icon: {
            type: String,
            required: false,
        }
    },
    computed: {
        redirect() {
            return this.$helpers.getAffiliateLink(this.$store.state.currentSiteLocale, this.link)
        }
    }
}
</script>
