<template>
    <div class="layout">
        <Navbar
            :items="$store.state.navigation.body"
            :home-link="'/' + $store.state.currentSiteLocale"
            :fav-icon="require('~/static/favicon.webp')"
            :breakpoint="900"
            nav-text="Compinero"
        />

        <Nuxt />

        <ScrollTop />

        <Footer />

        <client-only>
            <CookieConsent
                :content="$prismic.asText($store.state.translations.cc_description)"
                :button-text="$store.state.translations.cc_close"
            />
        </client-only>
    </div>
</template>
<script>
import Footer from "@/components/includes/TheFooter";
import { Navbar, ScrollTop, CookieConsent } from '@swegaming-ab/vue-components'

export default {
    components: { Navbar, ScrollTop, Footer, CookieConsent },
    async middleware({ store, $prismic, context, route }) {
        await store.dispatch('fetchStates', {$prismic, route});
    }
}
</script>
