import SiteConfig from '../site.config.js';
import { ConfigHelpers } from '@swegaming-ab/swegaming-prismic';

let config = new ConfigHelpers(require('../site.config.js').default);

export default function (document, includeAppUrl = false) {

    if(!document) {
        return false;
    }

    if(typeof document.type === 'undefined') {
        return false;
    }

    var path = '';

    if(includeAppUrl) {
        path = path + process.env.APP_URL;
    }

    path = path + '/' + config.getSiteLocaleFromDocument(document);

    if(document.type == 'page' && document.uid == 'home') {
        return path + '/';
    }

    if(document.type == 'lender') {
        return path + '/' + getPostTypeParent(document, 'lender') + '/' + document.uid + '/';
    }

    if(document.type == 'credit_card') {
        return path + '/' + getPostTypeParent(document, 'credit_card') + '/' + document.uid + '/';
    }

    if(document.type == 'lp') {
        return path + '/lp/' + document.uid + '/';
    }

    var slug = '/' + document.uid;
    if( typeof document.data !== 'undefined' &&
        typeof document.data.parent !== 'undefined' &&
        typeof document.data.parent.uid !== 'undefined') {

        slug = '/' + document.data.parent.uid + slug;
    }

    return path + slug + '/';

};

// TODO: From package
function getPostTypeParent(document, postType) {
    for (var i = 0; i < SiteConfig.locales.length; i++) {
        if(SiteConfig.locales[i].prismic == document.lang) {
            for (var j = 0; j < SiteConfig.locales[j].post_types.length; j++) {
                if(SiteConfig.locales[i].post_types[j].prismic == postType) {
                    return SiteConfig.locales[i].post_types[j].site;
                }
            }
            return SiteConfig.locales[i].site;
        }
    }
}
