<template>
    <ul class="footer-grid__list">
        <li>{{ header }}</li>
        <li
            v-for="(link, index) in links"
            :key="index"
        >
            <nuxt-link
                :to="$prismic.linkResolver(link.link)"
                :key="link.index"
            >
                {{link.text}}
            </nuxt-link>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        links: {
            type: Array,
            required: true,
            default: () => {}
        },
        header: {
            type: String,
            required: true,
            default: String
        }
    }
}
</script>
