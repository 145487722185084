<template>
    <Section
        width="lg"
        class="banner"
    >
        <img
            src="@/assets/images/404.png"
            alt="Compinero.com"
        />

        <div class="button">
            <nuxt-link
                to="/"
                class="btn btn-orange btn-lg"
            >
                Take me back
            </nuxt-link>
        </div>
    </Section>
</template>

<script>
export default {
    layout: 'site'
}
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $blue;
    @include border(bottom, 1px, $white);

    .section-inner {
        @include flex;

        img {
            @include size(500px, auto);
            max-width: calc(100% - 40px);
        }

        .button {
            width: 100%;
            @include flex;
        }
    }
}
</style>
