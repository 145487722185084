
export default {
    locales: [
        {
            site: 'sv',
            prismic: 'sv-se',
            icon: 'sweden.png',
            title: 'Sweden',
            post_types: [
                { site: 'lan', prismic: 'lender' },
                { site: 'kreditkort', prismic: 'credit_card' }
            ]

        },
        {
            site: 'no',
            prismic: 'no',
            icon: 'norway.png',
            title: 'Norway',
            post_types: [
                { site: 'lan', prismic: 'lender' },
                { site: 'kredittkort', prismic: 'credit_card' }
            ]

        },
        {
            site: 'es',
            prismic: 'es-es',
            icon: 'spain.png',
            title: 'Spain',
            post_types: [
                { site: 'prestamos', prismic: 'lender' },
                { site: 'targetas-de-credito', prismic: 'credit_card' }
            ]
        },
        {
            site: 'fi',
            prismic: 'fi',
            icon: 'finland.png',
            title: 'Finland',
            post_types: [
                { site: 'lainaa', prismic: 'lender' },
                { site: 'luottokortti', prismic: 'credit_card' }
            ]

        },
    ]
}
