<template>
    <div class="search">
        <transition name="search" mode="out-in">
            <i @click="open = true" v-if="!open" class="fas fa-search white click search-icon-closed"></i>
            <i @click="open = false, query = ''" v-else class="fas fa-search-minus white click search-icon-open"></i>
        </transition>

        <div class="search-results">
            <transition name="search">
                <input
                    v-if="open"
                    type="text"
                    ref="query"
                    v-model="query"
                    :placeholder="placeholder"
                    class="br-5 container-shadow search-input"
                    @keydown.esc="close()"
                />
            </transition>

            <transition name="search">
                <ul
                    v-if="query.length > 2 && open"
                    class="results bg-white bxs padding-0 margin-top-0 br-5"
                >
                    <li
                        v-if="filteredList.length"
                        v-for="(item, index) in filteredList"
                        :key="index"
                        @mouseup="open = false, query = ''"
                    >
                        <nuxt-link
                            :to="$prismic.linkResolver(item)"
                            class="flex flex-keep margin-2 h-text-orange"
                        >
                            <PrismicImage
                                :img="item.data.logo"
                                :w="60"
                            />
                            <span class="margin-left-4 flex-1 f-14 black text-bold">{{ item.data.title }}</span>
                        </nuxt-link>
                    </li>
                    <li
                        v-else
                        class="padding-2"
                    >
                        <span class="flex-1 f-14 black text-bold">No matches</span>
                    </li>
                </ul>
            </transition>
        </div>
        <transition name="fade">
            <div
                v-if="open"
                style="z-index: -1;"
                class="overlay show-m"
                @click="open = false, query = ''"
            />
        </transition>
    </div>
</template>
<script>
    export default {
        props: {
            data: {
                type: Object,
                required: false
            },
            placeholder: {
                type: String,
                required: false,
                default: 'Start typing..'
            }
        },
        data() {
            return {
                query: '',
                filteredList: [],
                open: false
            }
        },
        watch: {
            open() {
                if(this.open) {
                    let self = this;
                    this.$nextTick(() => {
                        self.$refs.query.focus();
                    })
                }
            },
            query() {
                this.filteredList = [];
                Object.keys(this.data).forEach(key => {
                    let title = this.data[key].data.title;
                    var titleFormatted = title.toLowerCase();
                    if(titleFormatted.includes(this.query.toLowerCase())) {
                        this.filteredList.push(this.data[key]);
                    }
                })
            }
        },
        methods: {
            close() {
                this.query = '';
                this.open = false;
            }
        }
    }
</script>
<style lang="scss">
    .search {
        position: absolute;
        right: 75px;
        top: 17px;
        z-index: 3;
        i {
            position: absolute;
            right: 0;
            font-size: 28px;
        }
        ul {
            list-style-type: none;
            max-height: 500px;
            overflow-y: scroll;
            width: 230px;
            border: 1px solid #e6e6e6;
            img {
                width: 60px;
            }
        }
        input {
            position: relative;
            top: -5px;
            width: 150px;
            margin-right: 50px;
            padding: 10px 15px;
            border: 1px solid #e6e6e6;
            font-size: 14px;
            &:focus {
                outline: none;
                border: 1px solid #e98949;
            }
        }
        @media screen and (min-width: 900px) {
            top: 15px;
            right: 20px;
            ul {
                max-height: 500px;
                width: 330px;
            }
            input {
                width: 300px;
                margin-right: 60px;
                top: -5px;
                padding: 10px 15px;
                font-size: 16px;
            }
        }
        .search-enter-active {
            transition: all .15s ease-in-out;
        }
        .search-leave-active {
            transition: all .15s ease-in-out;
        }
        .search-enter, .search-leave-to {
            transform: translateX(20px);
            opacity: 0;
        }
    }
</style>
