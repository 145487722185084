<template>
    <div
        class="header-section"
        :class="align"
    >
        <div v-html="header"></div>

        <p v-if="teaser.length">{{ teaser }}</p>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: true,
            default: 'Title'
        },
        teaser: {
            type: String,
            required: false,
            default: ''
        },
        align: {
            type: String,
            required: false,
            default: 'center'
        }
    }
}
</script>

<style lang="scss" scoped>
.header-section.right { text-align: right; }
.header-section.left { text-align: left; }
.header-section.center { text-align: center; }

.header-section::v-deep {
    width: 100%;

    h2,
    p { @include spacing(margin,6,bottom); }
    p { @include font-size(18px, 10px); }
}

.banner {
    .header-section {
        p {
            @include spacing(margin,6,top);
            margin-bottom: 0;
        }
    }
}
</style>
