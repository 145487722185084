var _ = require('lodash');
import { Helpers, ConfigHelpers } from '@swegaming-ab/swegaming-prismic';
import { DateTime } from 'luxon';

export const state = () => ({
    currentPrismicLocale: null,
    currentSiteLocale: null,
    navigation: {},
    translations: {},
    settings: {},
    lenders: [],
    lists: [],
    authors: [],
    // slugs: []
})

export const mutations = {
    SET_NAVIGATION(state, navigation) {
        state.navigation = replace(navigation);
    },

    SET_TRANSLATIONS(state, translations) {
        state.translations = replace(translations);
    },

    SET_SETTINGS(state, settings) {
        state.settings = replace(settings);
    },

    SET_LENDERS(state, lenders) {
        state.lenders = replace(lenders);
    },

    SET_LISTS(state, lists) {
        state.lists = replace(lists);
    },

    SET_AUTHORS(state, authors) {
        state.authors = replace(authors);
    }

    // SET_SLUGS(state, slugs) {
    //     state.slugs = slugs;
    // }
}

export const actions = {
    async fetchStates({ commit }, payload) {

        try {

            let $prismic = payload.$prismic;

            // TODO change fromPage name
            // fromPage includes prismic, the route etc.
            // and everything else we need from the page
            let config = new ConfigHelpers(require('@/site.config.js').default);
            let helper = new Helpers();

            let siteLocale = getSiteLocaleFromPath(helper.splitPathToArray(payload.route.path));
            let prismicLocale = config.getPrismicLocaleFromSite(siteLocale);

            if(this.state.currentPrismicLocale != prismicLocale) {

                // update site and prismic locale
                this.state.currentSiteLocale = siteLocale;
                this.state.currentPrismicLocale = prismicLocale;

                // get the navigation
                const navigation = await $prismic.api.getSingle('navigation', {lang: prismicLocale, fetchLinks: 'page.parent'});
                if(navigation && navigation.data) {
                    commit('SET_NAVIGATION', navigation.data);
                }

                // translations
                const translations = await $prismic.api.getSingle('translations', {lang: prismicLocale});
                if(translations && translations.data) {
                    commit('SET_TRANSLATIONS', translations.data);
                }

                // settings
                const settings = await $prismic.api.getSingle('settings', {lang: prismicLocale, fetchLinks: 'page.parent'});
                if(settings && settings.data) {
                    commit('SET_SETTINGS', settings.data);
                }

                // TODO loop if we dont have all credit cards
                const lenders = await $prismic.api.query(
                    $prismic.predicates.at('document.type', 'lender'),
                    {lang: prismicLocale, pageSize: 100}
                );

                const authors = await $prismic.api.query(
                    $prismic.predicates.at('document.type', 'author'),
                    {lang: prismicLocale, pageSize: 100}
                );

                if(lenders.results_size > 0) {
                    commit('SET_LENDERS', _.keyBy(lenders.results, 'id'));
                }

                if(authors.results_size > 0) {
                    commit('SET_AUTHORS', _.keyBy(authors.results, 'id'));
                }

                const lists = await $prismic.api.query(
                    $prismic.predicates.at('document.type', 'list'),
                    {lang: prismicLocale, pageSize: 100}
                );

                if(lists.results_size > 0) {
                    commit('SET_LISTS', _.keyBy(lists.results, 'id'));
                }

            }

        } catch (e) {
            console.log(e);
        }
    }
}

function getSiteLocaleFromPath(pathArray) {
    return pathArray[0];
}

// TODO: Implement from nuxtjs-helpers
const replace = (json) => {
    const dt = DateTime.now();
    const currentYear = dt.year;
    return JSON.parse(
        JSON.stringify(json)
            .replace(/%year%/g, currentYear)
    );
}
