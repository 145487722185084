import Vue from 'vue'
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

import {
    Button,
    ButtonNuxt,
    PrismicImage,
    Section,
    QuestionAndAnswer
} from '@swegaming-ab/vue-components';

import ButtonTarget from '@/components/buttons/ButtonTarget.vue';
import Search from '@/components/Search.vue';
import HeaderSection from '@/components/HeaderSection.vue';

const components = {
    PrismicImage,
    ButtonNuxt,
    ButtonTarget,
    Search,
    Section,
    QuestionAndAnswer,
    Button,
    HeaderSection
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
