/**
* To learn more about HTML Serializer check out the Prismic documentation
* https://prismic.io/docs/vuejs/beyond-the-api/html-serializer
*/
import Vue from 'vue';

import linkResolver from "./link-resolver"
import prismicDOM from 'prismic-dom'
let he = require('he');

let $store = require('../store/index.js');

const Elements = prismicDOM.RichText.Elements

export default function (type, element, content, children) {

    // Generate links to Prismic Documents as <router-link> components
    // Present by default, it is recommended to keep this
    if (type === Elements.hyperlink) {
        let result = ''
        const url = prismicDOM.Link.url(element.data, linkResolver)

        if (element.data.link_type === 'Document') {

            // INFO
            // data-nuxt-link to be added to event listeners
            // to have a SPA feeling to the app. Should
            // be done in mounted()
            return `<a href="${url}" data-nuxt-link>${content}</a>`;
        }
    }
    // NOTE: This interferes with prismic label, i.e: removes <span>
    else if(type == Elements.heading2) {
        return `<h2 id="${Vue.prototype.$helpers.getIdString(element.text)}">${children.join('')}</h2>`;
    }
    else if(type === Elements.preformatted) {

        // return an html string, where the
        // breaks has been erased
        return he.decode(
            children.join('').split('<br />').join('')
        );
    }

    // Return null to stick with the default behavior for everything else
    return null;
}
