<template>
    <footer class="footer">
        <div class="footer-inner section-width-lg">
            <div class="footer-company">
                <div class="footer-company__logo">
                    <img
                        src="@/static/favicon.webp"
                        alt="Compinero"
                        loading="lazy"
                        class="logo-image"
                    />
                    <span class="logo-text">Compinero</span>
                </div>

                <p class="footer-company__text">{{$prismic.asText($store.state.settings.footer_text)}}</p>
            </div>

            <div class="footer-grid">
                <LinksColumn
                    v-if="showList($store.state.settings.footer__first_link_list).length"
                    :links="$store.state.settings.footer__first_link_list"
                    :header="$store.state.settings.footer__first_link_list_header"
                />

                <LinksColumn
                    v-if="showList($store.state.settings.footer__second_link_list).length"
                    :links="$store.state.settings.footer__second_link_list"
                    :header="$store.state.settings.footer__second_link_list_header"
                />

                <LinksColumn
                    v-if="showList($store.state.settings.footer__third_link_list).length"
                    :links="$store.state.settings.footer__third_link_list"
                    :header="$store.state.settings.footer__third_link_list_header"
                />
            </div>

            <div class="footer-markets">
                <MarketSelector :data="locales" />
            </div>

            <div class="footer-copy">{{$prismic.asText($store.state.settings.footer_copyright)}}</div>
            <div class="footer-privacy"><a target="_blank" href="/privacy.pdf">Privacy Policy</a></div>
        </div>
    </footer>
</template>

<script>
import MarketSelector from '../MarketSelector.vue';
import LinksColumn from './TheFooterLinksColumn.vue';
let siteConfig = require('@/site.config.js').default;

// TODO add meta descriptions
export default {
    components: { MarketSelector, LinksColumn },
    data() {
        return {
            locales: siteConfig.locales
        }
    },
    methods: {
        showList(items) {
            return items.filter(item => item.link.id);
        }
    }
}
</script>

<style lang="scss" scoped>
.footer::v-deep {
    background: $blue;
    @include flex;
    padding: 40px 20px;
    @include border(top);

    @include device(pad) { padding: 60px 20px; }

    @include device(desktop) { padding: 80px 20px; }

    .footer-inner {
        @include flex(center,start);

        .footer-company {
            flex: 1;
            max-width: 300px;
            @include grid(1, 20px);
            @include spacing(margin,8,bottom);
            text-align: center;

            @include device(desktop) {
                padding-right: 40px;
                text-align: left;
                margin-bottom: 0;
            }

            &__logo {
                @include flex;

                @include device(desktop) {
                    @include flex(start);
                }

                .logo-image {
                    @include size(40px);
                    @include spacing(margin, 4, right);
                }

                .logo-text {
                    font-family: $fontHeader;
                    color: $white;
                    @include font-size(26px);
                }
            }

            &__text {
                color: $white;
            }
        }

        .footer-grid {
            width: 100%;
            @include grid(1, 40px);

            @include device(pad) {
                @include grid(3, 20px);
            }

            @include device(desktop) {
                width: auto;
                flex: 1;
            }

            &__list {
                li {
                    @include flex;
                    line-height: 30px;

                    @include device(desktop) {
                        display: block;
                    }

                    a {
                        color: $white;
                        font-size: 14px;
                        &:hover { color: $orange; }
                    }

                    &:first-child {
                        color: $orange;
                        font-weight: bold;
                        font-size: 16px;
                    }
                }
            }
        }

        .footer-markets {
            width: 100%;
            @include spacing(margin,8,(top,bottom));
            @include spacing(padding,4,(top,bottom));
            @include border((bottom,top),1px,rgba(255,255,255,.5));
        }

        .footer-copy {
            width: 100%;
            @include flex;
            color: $white;
            font-family: $fontText;

            a {
                color: $white;
            }
        }

        .footer-privacy {
            padding-top: 20px;
            width: 100%;
            @include flex;
            color: $white; 
            font-family: $fontText;

            a {
                text-decoration: underline;
                color: $white;
            }
        }
    }
}
</style>
