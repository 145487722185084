import Vue from 'vue';

Vue.prototype.$helpers = {
    filterEmptyRelations: (relation) => {
        let key = Object.keys(relation)[0];
        return relation[key] && relation[key].id;
    },

    filterEmptyElements: (element) => {
        let key = Object.keys(element)[0];
        return element[key];
    },

    getAffiliateLink: (siteLocale, lender) => {
        if(!lender.data.redirect) {
            return '';
        }

        return '/' + siteLocale + '/out/' + lender.uid + '/';
    },

    removeParagraphs: (string) => {
        return string.replace('<p>', '').replace('</p>', '');
    },

    removeH1s: (string) => {
        return string.replace('<h1>', '').replace('</h1>', '');
    },

    removeHeaders: (string) => {
        return string
            .replace('<h1>', '').replace('</h1>', '')
            .replace('<h2>', '').replace('</h2>', '')
            .replace('<h3>', '').replace('</h3>', '')
            .replace('<h4>', '').replace('</h4>', '')
            .replace('<h5>', '').replace('</h5>', '')
            .replace('<h6>', '').replace('</h6>', '');
    },

    getIdString: (string) => {
        return string
            .replace(/ /gi, '-')
            .replace(/å/gi, 'a')
            .replace(/ä/gi, 'a')
            .replace(/ö/gi, 'o')
            .toLowerCase();
    },

    truncateString: (string, length = 0 ) => {

        if(length == 0) {
            return string;
        }

        if (string.length > length - 10) {
            return string.substring(0, length) + '...';
        }

        return string;
    }
}
